import React, { useState, useRef, useContext } from "react"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Row from "@amzn/meridian/row"
import { HELP_LINKS } from '../../utils/constants'

const Help = () => {
    const buttonRef = useRef()
    const [show, setShow] = useState(false)

    return (
        <React.Fragment>
            <Button
                onClick={ () => setShow(true) }
                circular
                type='link'
                ref={ buttonRef }
            >
                <Icon tokens={ helpTokens } />
            </Button>
            <Popover
                anchorNode={ buttonRef.current }
                open={ show }
                onClose={ () => setShow(false) }
                position="bottom"
            >
            {
                HELP_LINKS.map((link, index) => {
                    return (
                        <Row key={ index }>
                            <Link
                                href={ link.url }
                                type="secondary"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                { link.label }
                            </Link>
                        </Row>
                    )
                })
            }
            </Popover>
        </React.Fragment>
    )
}

export default Help