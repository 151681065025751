import { useState, useEffect } from 'react';
import axios from 'axios'
import { unmarshall } from "@aws-sdk/util-dynamodb";
import Cohorts from '../types/Cohort'
import getAlias from '../utils/getAlias'
import { COHORTS_BUILDER_URL } from '../utils/constants'

const useCohorts = (login: string): [Cohorts[], boolean, null] => {
    const initCohorts = [] as Cohorts[];
    const [data, setData] = useState(initCohorts);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
      // Set loading and error states
      setLoading(true);
      setError(null);

      getAlias().then(alias =>{
        // Use cohort builder API to retrieve all cohorts for alias
        axios.get(`${COHORTS_BUILDER_URL}/${login}`)
        .then (response => {
            let unmarshalledResponse =  response.data.Items.map(element => {
              return unmarshall(element)
            })
            setLoading(false)
            setData(unmarshalledResponse)
        })
        .catch(err => {
            setError(err)
            setLoading(false)
        })
      })
    }, [])
    return [data, loading, error];
  }

export default useCohorts;