import Row from "@amzn/meridian/row"
import Tile from "@amzn/meridian/tile"
import Metric from "@amzn/meridian/metric"
import Heading from "@amzn/meridian/heading"
import ExtendedPracticeCase from "../../../types/ExtendedPracticeCase";

type propType = {
    practiceCases: ExtendedPracticeCase[]
}

const AggregatedData = (props: propType) => {
    const { practiceCases } = props

    let totalScore: number = 0
    let correctAnswers = 0
    let totalAnswers = 0

    for (const practiceCase of practiceCases) {
        if (practiceCase.trainerAction && practiceCase.trainerAction !== '-') {
            ++totalAnswers
            totalScore += parseFloat(practiceCase.finalScore as string)
            if (practiceCase.learnerAction === practiceCase.trainerAction) {
                ++correctAnswers
            }
        }
    }

    const tiles = [
        {
            label: 'Practiced Cases',
            value: practiceCases.length
        },
        {
            label: 'Total AR',
            value: (correctAnswers / totalAnswers) * 100
        },
        {
            label: 'Avg Score',
            value: totalScore / totalAnswers
        }
    ]

    return (
        <Row
            widths={['grid-3', 'grid-3', 'grid-3']}
            spacing='medium'
            spacingInset='xsmall'
        >
        {
            tiles.map((tile, index) => {
                return (
                    <Tile spacingInset="450" key={ index }>
                            <Heading level={2} type="h200" color="secondary">
                            { tile.label }
                            </Heading>
                            <Metric numberFormat={{ maximumSignificantDigits: 3, notation: "compact" }}>
                            { tile.value }
                            </Metric>
                    </Tile>
                )
            })
        }
        </Row>
    )
}

export default AggregatedData