import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';

interface ScoredCase {
    caseId: string;
    trainerAction: string;
    learnerAction: string
    score: number
}

export interface ScoredCohort {
    login: string;
    totalActions: number;
    correctActions: number;
    arPercentage: number;
    questionPercentages: number[];
    cases: ScoredCase[];
}

interface ScoredCohortData {
    columnDisplayNames?: string[],
    scores: ScoredCohort[]
    templateName: string;
}

const useScoreCohort = (cohortId): [ScoredCohortData[], boolean, null] => {

    const initCohorts = [] as ScoredCohortData[];
    const [data, setData] = useState(initCohorts);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        //Set loading and error states
        setLoading(true);
        setError(null);

        apiCaller.get(`/trainer/cohort?cohortId=${cohortId}`)
            .then((response) => {
                setLoading(false)
                setData(response.data as ScoredCohortData[])
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [cohortId])
    return [data, loading, error];
}

export default useScoreCohort;