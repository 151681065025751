import React, { useEffect, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Column from "@amzn/meridian/column";
import AppLayout from "@amzn/meridian/app-layout"
import Header from './Header/Header';
import TrainerLanding from './TrainerView/TrainerLanding'
import PracticeCase from './PracticeCase/PracticeCase'
import ComponentPlayground from './ComponentPlayground/ComponentPlayground'
import LearnerHomePage from './LearnerView/HomePage/LearnerHomePage'
import { UserDetailsContext } from '../contexts/UserDetailsProvider'
import useRole from '../hooks/useRole'
import CohortDashboard from './TrainerView/CohortDashboard'
import TrainerSideMenu from './TrainerView/TrainerSideMenu'
import LearnerPerformanceDashboard from './LearnerView/PerformanceDashboard/LearnerPerformanceDashboard'
import CaseDashboard from './TrainerView/CaseDashboard';

export default function App(props: any) {
    const { login } = props
    const { role, loading } = useRole(login)
    const { userDetails, setUserDetails } = useContext(UserDetailsContext)

    useEffect(() => {
        //For local testing you can change this to whatever login/role you want to test with
        if (role) {
            setUserDetails({
                login: props.login,
                role: role
                // login: 'login4',
                // role: 'learner'
            })
        }
    }, [role])
    
    if (loading) return <h1>Authorizing...</h1>
    
    return (
        <AppLayout
            headerComponent={ Header }
            backgroundColor="alternateSecondary"
            sidebarComponent={TrainerSideMenu}
        >
            <Header />
            {userDetails.role !== 'learner' && <TrainerSideMenu />}
            <Column spacingInset={"300 500"}>
                <Routes>
                    <Route path='/learner' element={<LearnerHomePage />} />
                    <Route path='/learner/performance' element={<LearnerPerformanceDashboard />} />
                    <Route path="/trainer" element={
                        userDetails.role === 'trainer' 
                        ?   <TrainerLanding />
                        :   <Navigate to='/learner' />
                    }/>
                    <Route path="/trainer/cohort/:cohortId/case/:caseId/"
                        element={
                            userDetails.role === 'trainer' ?
                            <CaseDashboard />
                            :
                            <Navigate to='/learner' />
                        }/>
                    <Route path="/trainer/cohort/:cohortId"
                        element={
                            userDetails.role === 'trainer' ?
                            <CohortDashboard />
                            :
                            <Navigate to='/learner' />
                    }/>
                    <Route path='/component-playground' element={<ComponentPlayground />} />
                    
                    <Route
                        path="/practice-cases/cohort/:cohortId/caseId/:practiceCaseId/login/:login"
                        element={<PracticeCase />}
                    />
                    <Route
                        path="/practice-cases/cohort/:cohortId/caseId/:practiceCaseId"
                        element={<PracticeCase />}
                    />
                    <Route path='/component-playground' element={<ComponentPlayground />} />
                    <Route 
                        path='/'
                        element={
                            userDetails.role === 'learner'
                            ?   <Navigate to='/learner' />
                            :   <Navigate to='/trainer' />
                        }
                    />
                </Routes>
            </Column>
        </AppLayout>
    );
}
