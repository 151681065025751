import React, { useState, useEffect } from 'react';
import Table, { TableRow, TableCell } from "@amzn/meridian/table"

const TableRows = ["Total Actions Taken", "% of Total Results"]
const CaseFinalTable = ({ caseQuestionData }) => {

    return (
        <Table key='case-final-table' headerRows={1} spacing="small" showDividers nestedRows rowComponents={[TableRow]}>
            <TableRow key='case-final-table-header'>
                <TableCell>
                    {/* Empty Cell for 1st Column */}
                </TableCell>
                {caseQuestionData.finalAction &&
                    caseQuestionData.finalAction.map((header) => {
                        const headerColor = header.answerLabel === caseQuestionData.trainerFinalAction ? "LightGreen" : "primary"
                        return (
                            <TableCell  backgroundColor={headerColor} >
                                {header.answerLabel}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
            {caseQuestionData.finalAction &&
                TableRows.map((q, ind) => {
                    return (
                        <TableRow key={q}>
                            <TableCell>
                                {q}
                            </TableCell>
                            {
                                caseQuestionData.finalAction.map((a) => {
                                    const cellColor = a.answerLabel === caseQuestionData.trainerFinalAction ? "LightGreen" : "primary"

                                    if (ind !== TableRows.length - 1) {
                                        return (
                                            <TableCell backgroundColor={cellColor}>
                                                {a.tally}
                                            </TableCell>
                                        )
                                    } else {
                                        return (
                                            <TableCell backgroundColor={cellColor}>
                                                {(a.tally / caseQuestionData.totalResponses * 100).toFixed(2)}%
                                            </TableCell>
                                        )
                                    }
                                })
                            }
                        </TableRow>
                    )
                })
            }
        </Table>
    )
}

export default CaseFinalTable