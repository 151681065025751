import React, { useState } from "react";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import { LEARNER_HOW_TO_INSTRUCTIONS } from '../../../utils/constants'
import Column from "@amzn/meridian/column";

const LearnerHomePageInstructions = () => {
    const [showInstructions, setShowinstructions] = useState(true)

    return (
        <Column>
            <Heading level={6} type="h300">
                How to use LITT Case Practice
            </Heading>
            <Row>
                <div></div>
                <Text tag='ol' type="b300">
                {
                    LEARNER_HOW_TO_INSTRUCTIONS.map((instruction, index) => {
                        return <li key={ index }>{ instruction }</li>
                    })
                }
                </Text>
            </Row>
        </Column>
    )
}

export default LearnerHomePageInstructions;