import React, { useState, useRef, useContext } from "react";
import Row from "@amzn/meridian/row"
import Column from "@amzn/meridian/column"
import Icon from "@amzn/meridian/icon"
import Input from "@amzn/meridian/input"
import Button from "@amzn/meridian/button"
import Loader from "@amzn/meridian/loader"
import Text from "@amzn/meridian/text"
import Alert from "@amzn/meridian/alert"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Select, { SelectOption } from "@amzn/meridian/select"
import plusTokens from "@amzn/meridian-tokens/base/icon/plus"
import { useNavigate } from "react-router";
import useTemplates from '../../hooks/useTemplates'
import { createPracticeCasesForCohort } from '../../utils/api'
import QuestionnaireTemplateType from '../../types/Template'
import { UserDetailsContext } from "../../contexts/UserDetailsProvider"
import useCohortContext from "../../hooks/useCohortContext";

const NewPracticeCase = (props) => {
    const buttonRef = useRef()
    const navigate = useNavigate()
    const {cohortId} = props
    const {userDetails} = useContext(UserDetailsContext)
    const {cohortDetails} = useCohortContext();
    const [open, setOpen] = useState(false)
    const [templateId, setTemplateId] = useState('')
    const [caseId, setCaseId] = useState('')
    const [caseUrl, setCaseUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [templates, templatesLoading, templatesError] = useTemplates()
    const validCaseUrlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator

    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)
    const handleTemplateChange = (selectedTemplateId: string) => setTemplateId(selectedTemplateId)

    const isNotValidURL = (urlString) => {
        return !validCaseUrlPattern.test(urlString);
    };
    const onSubmit = async () => {

        const missingFields = `${ templateId === '' ? '[Template]' : '' }${ caseId === '' && caseUrl === '' ? '[Case ID] or [Case Url]' : '' }`
        setErrorMessage('')
        
        const urlError =
            caseId === "" && isNotValidURL(caseUrl)
                ? "Please enter a valid Case Url"
                : "";
        if (missingFields !== '') {
            setErrorMessage(`The following fields are required: ${ missingFields }`)
        } else if (urlError !=='') {
            setErrorMessage(urlError);
        } else {
            setLoading(true)

            const selectedTemplate = templates.find(template => template.templateId === templateId)
            const practiceCase = {
                createdBy: userDetails.login,
                cohortId: cohortId,
                org: selectedTemplate?.org,
                skill: selectedTemplate?.function,
                practiceCaseId: caseId.trim(),
                caseUrl: caseUrl.trim(),
                templateId: templateId
            }
            console.log("PRACTICE CASE:\n", practiceCase)
            
            try {
                await createPracticeCasesForCohort(practiceCase)
                setLoading(false)
                setOpen(false)
                navigate(`/practice-cases/cohort/${cohortId}/caseId/${caseId}`)
            } catch (error: any) {
                setErrorMessage(`An error occurred while creating the practice case, ${error.message}`)
            }
        }
    }

    const publishedTemplates = templates.filter(template => template.templateStatus === 'published' && template.org === cohortDetails.org)

    return (
        <React.Fragment>
            <Button onClick={ onOpen } circular ref={ buttonRef }>
                <Icon tokens={ plusTokens }></Icon>
            </Button>
            <Popover
                anchorNode={ buttonRef.current }
                open={ open }
                onClose={ onClose }
                position="bottom"
            >
                <PopoverHeader closeLabel="Close">Create New Practice Case</PopoverHeader>
                {
                    templatesError &&
                    <Text>An error ocurred while trying to fetch the available templates for this cohort</Text>
                }
                {
                    templatesLoading
                    ? <Loader type="linear"/>
                    : <Row alignmentVertical='bottom'>
                        <Column>
                            <Select
                                value={ templateId }
                                onChange={ handleTemplateChange }
                                placeholder="Select a template"
                                width={ 300 }
                                helperText="Available templates for this cohort"
                            >
                                {
                                    publishedTemplates.map((template: QuestionnaireTemplateType) => {
                                        return <SelectOption
                                            key={ template.templateId }
                                            value={ template.templateId }
                                            label={ template.templateName }
                                        />
                                    })
                                }
                            </Select>
                        </Column>
                        <Column>
                            <Input
                                value={ caseId }
                                onChange={ setCaseId }
                                type="text"
                                placeholder="Enter a Case/Task Id"
                                helperText="Case or Task Id to be reviewed"
                            />
                        </Column>
                        <Column>
                            <Input
                                value={ caseUrl }
                                onChange={ setCaseUrl }
                                type="text"
                                placeholder="Enter Case Link"
                                helperText="Case link to be reviewed"
                            />
                        </Column>
                        <Column>
                            <Button type='tertiary' onClick={ onSubmit } disabled={ loading }>
                                {
                                    loading &&
                                    <Loader type="circular" size="small"/>
                                }
                                Go
                            </Button>
                        </Column>
                    </Row>
                }
                {
                    errorMessage !== '' &&
                    <Column spacingInset={ "small" }>
                        <Alert type='error' size='small'>{ errorMessage }</Alert>
                    </Column>
                }
            </Popover>
        </React.Fragment>
    )
}

export default NewPracticeCase;