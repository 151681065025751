import React from 'react'
import CaseQuestionTable from './CaseQuestionTable'
import CaseFinalTable from './CaseFinalTable'
import useScoreCase from '../../hooks/useScoreCase'
import { useParams } from 'react-router'
import Column from '@amzn/meridian/column'
import Row from '@amzn/meridian/row'
import Alert from '@amzn/meridian/alert'
import Box from '@amzn/meridian/box'
import Text from '@amzn/meridian/text'
import EditCaseButton from './EditCaseButton'
import Loader from '@amzn/meridian/loader'


const CaseDashboard = () => {
    const { cohortId, caseId } = useParams();
    const [caseQuestionData, caseQuestionLoading, caseQuestionError] = useScoreCase(cohortId, caseId);


    const findARCal = () => {
        const arP = caseQuestionData.finalAction.find((e) => e.answerLabel === caseQuestionData.trainerFinalAction)
        if (arP) {
            return ((arP.tally / caseQuestionData.totalResponses) * 100).toFixed(2)
        }
        return 'nil'
    }


    if (caseQuestionData?.message) return (
        <Column spacingInset={"500 200"}>
            <Alert type='warning'>{caseQuestionData.message}</Alert>
            <Row widths={'fill'}>
                <Box height={200}>
                    <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                        <Text type={'h500'}>
                            Edit Case
                        </Text>
                        <EditCaseButton cohortId={cohortId as string} caseId={caseId as string} />
                    </Column>
                </Box>
            </Row>
        </Column>
    )

    if (caseQuestionLoading || (!caseQuestionData.totalResponses && caseQuestionData.totalResponses !== 0) || caseQuestionError) return (<Loader />)


    return (
        <Column spacingInset={"500 200"}>
            <Row widths={'fill'}>
                <Box type={'outline'} height={200}>
                    <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                        <Text type={'h500'}>
                            Total Responses
                        </Text>
                        <Text type={'h500'}>
                            {caseQuestionData.totalResponses}
                        </Text>
                    </Column>
                </Box>
                <Box type={'outline'} height={200}>
                    <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                        <Text type={'h500'}>
                            Task AR Cal
                        </Text>
                        <Text type={'h500'}>
                            {findARCal()}%
                        </Text>
                    </Column>
                </Box>
                <Box height={200}>
                    <Column alignmentHorizontal='center' spacingInset={'500'} spacing={'500'}>
                        <Text type={'h500'}>
                            Edit Case
                        </Text>
                        <EditCaseButton cohortId={cohortId as string} caseId={caseId as string} />
                    </Column>
                </Box>
            </Row>
            {
                caseQuestionData.totalResponses !== 0 ?
                    <>
                        <CaseQuestionTable
                            caseQuestionData={caseQuestionData}

                        />
                        <CaseFinalTable
                            caseQuestionData={caseQuestionData}
                        />
                    </>
                :
                <Alert type='informational'>No learner has submitted a practice case.</Alert>
            }

        </Column>
    )
}

export default CaseDashboard