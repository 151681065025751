import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';


interface QuestionInfo {
    tally: number;
    questionLabel: string;
    trainerResponse: string;
}

interface FinalActionInfo {
    tally: number;
    answerLabel: string;
}

interface ScoredCaseData {
    totalResponses: number;
    trainerFinalAction: string;
    questionInfo: QuestionInfo[]
    finalAction: FinalActionInfo[]
    message?: "string"
}


const useScoreCase = (cohortId, caseId): [ScoredCaseData, boolean, null] => {

    const initCase = {} as ScoredCaseData;
    const [data, setData] = useState(initCase);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        //Set loading and error states
        setLoading(true);
        setError(null);

        apiCaller.get(`/trainer/case?cohortId=${cohortId}&caseId=${caseId}`)
            .then((response) => {
                setLoading(false)
                setData(response.data as ScoredCaseData)
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [cohortId, caseId])
    return [data, loading, error];
}

export default useScoreCase;