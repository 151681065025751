import React, { useState, useContext } from "react"
import Masthead, {
  MastheadTitle,
  MastheadLink,
} from "@amzn/meridian/masthead"
import { useNavigate } from "react-router";
import Logo from "./Logo"
import Help from "./Help"
import HeaderLink from "../../types/HeaderLink"
import { HEADER_LINKS_BY_ROLE } from '../../utils/constants'
import { UserDetailsContext } from "../../contexts/UserDetailsProvider"

const Header = () => {
    const { userDetails } = useContext(UserDetailsContext)
    const [selected, setSelected] = useState<string>()
    const navigate = useNavigate()
    const onClickLink = (linkObject: HeaderLink) => {
        setSelected(linkObject.id)
        navigate(linkObject.route)
    }
    
    const role = userDetails.role

    return (
        <Masthead size="large">
            <MastheadTitle href="/#/">
                <Logo />
            </MastheadTitle>
            {
                role &&
                HEADER_LINKS_BY_ROLE[role].map((link: HeaderLink) => {
                    return <MastheadLink 
                        selected={selected === link.id}
                        // href={link.route}
                        onClick={() => onClickLink(link)}
                        key={ link.id }
                    >
                        { link.label }
                    </MastheadLink>
                })
            }
            <p>Welcome, { userDetails.login} </p>
            {
                userDetails.role === 'trainer' &&
                <Help />
            }
        </Masthead>
    )
}

export default Header