import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';
import PracticeCase from '../types/PracticeCase';

const usePracticeCasesForLogin = (login: string, includeTemplate = false, includePrime = false) => {
    const [practiceCases, setPracticeCase] = useState<PracticeCase[]>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        apiCaller.get(`/practice-cases/login?login=${login}`
            + `${ includeTemplate ? '&includeTemplate=true' : '' }`
            + `${ includePrime ? '&includePrimeAnswers=true' : '' }`)
        .then(response => {
            setPracticeCase(response.data as PracticeCase[])
            setLoading(false)
        })
        .catch(err => {
            setError(err)
            setLoading(false)
        })
    }, [])

    return { practiceCases, loading, error };
}

export default usePracticeCasesForLogin;