import axios from 'axios';
import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';
import PracticeCase from '../types/PracticeCase';

const usePracticeCase = (cohortId: string, practiceCaseId: string, login: string) => {
    const [practiceCase, setPracticeCase] = useState<PracticeCase>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        apiCaller.get(`/practice-cases/case?cohortId=${cohortId}&practiceCaseId=${practiceCaseId}&login=${login}`)
        .then(response => {
            setPracticeCase(response.data as PracticeCase)
            setLoading(false)
        })
        .catch(err => {
            setError(err)
            setLoading(false)
        })
    }, [cohortId, practiceCaseId, login])
    return { practiceCase, loading, error };
}

export default usePracticeCase;