import React from "react";
import LearnerPracticeCases from './LearnerPracticeCases'
import LearnerHomePageInstructions from './LearnerHomePageInstructions'
import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider"

const LearnerHomePage = () => {
    return (
        <Column spacing='medium'>
            <div></div>
            <LearnerHomePageInstructions />
            <Divider />
            <LearnerPracticeCases />
        </Column>
    )
}

export default LearnerHomePage;