import React, { useState, useEffect } from 'react';
import Table, { TableRow, TableCell } from "@amzn/meridian/table"


const TableHeaders = [["Question","questionLabel"],["Trainer Response", "trainerResponse"], ["CR Count", "tally"], ["AR%","tally"]]
const CaseQuestionTable = ({caseQuestionData}) => {
    return (
        <Table key='case-table' headerRows={1} spacing="small" showStripes showDividers nestedRows rowComponents={[TableRow]}>
            <TableRow key='case-table-header'>
                {
                    TableHeaders.map((col) => {
                        return (
                            <TableCell>
                                {col[0]}
                            </TableCell>
                        )
                    })
                }
            </TableRow>
            {   caseQuestionData.questionInfo  &&
                caseQuestionData.questionInfo.map((q) => {
                    return (
                        <TableRow key={q.questionLabel}>
                            {
                                TableHeaders.map((col, ind) => {
                                    if(ind != TableHeaders.length-1) {
                                        return (
                                            <TableCell>
                                                {q[col[1]]}
                                            </TableCell>
                                        )
                                    } else {
                                        return (
                                            <TableCell>
                                                {(q[col[1]]/caseQuestionData.totalResponses * 100).toFixed(2)}%
                                            </TableCell>
                                        )
                                    }
                                })
                            }
                        </TableRow>
                    )
                })
            }
        </Table>
    )
}

export default CaseQuestionTable