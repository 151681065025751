// import React, { useState } from "react";
import Box from "@amzn/meridian/box";
import Loader from "@amzn/meridian/loader"
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";
import { UserDetailsContext } from "../../../contexts/UserDetailsProvider"
import usePracticeCasesForLogin from '../../../hooks/usePracticeCasesForLogin'
import PracticeCaseCard from './PracticeCaseCard'
import { useContext } from "react";

const LearnerPracticeCases = () => {
    const { userDetails } = useContext(UserDetailsContext)
    const { practiceCases, loading, error } = usePracticeCasesForLogin(userDetails.login as string)

    if (loading) return <Loader />
    if (error) return <Alert type='error'>An error occurred while fetching your practice cases</Alert>

    const pendingCases = practiceCases?.filter(practiceCase => {
        return practiceCase.practiceCaseStatus !== 'completed'
    })

    if (pendingCases?.length === 0) return <Alert type="informational">No Pending or In Progress practice cases.</Alert>

    return (
        <Box>
            <Row  wrap="down" spacing='large'>
            {
                pendingCases?.map((practiceCase, index) => {
                    return (
                        <PracticeCaseCard 
                            practiceCase={ practiceCase }
                            key={ index }
                        />
                    )
                })
            }
            </Row>
        </Box>
    )
}

export default LearnerPracticeCases;