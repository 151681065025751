import React from "react";
import NewPracticeCase from '../NewPracticeCase/NewPracticeCase'
import PracticeCase from "../PracticeCase/PracticeCase";
import CohortTable from '../TrainerView/CohortTable'
import CohortSelector from "../TrainerView/CohortSelector";
import LearnerCoaching from '../TrainerView/LearnerCoaching'

const ComponentPlayground = (props: any) => {

    return (
        <React.Fragment>
            {/* <CohortSelector changeHandler={() => {}} /> */}
            {/* <NewPracticeCase login='jeffjwl' cohortId='testcohort5-Week15-04-11-2022' /> */}
            {/* <PracticeCase /> */}
            {/* <PracticeCase role='learner' login='login1'/> */}
            {/* <PracticeCase role='learner' login='login1'/> */}
            {/* <Header role='learner' /> */}
            <LearnerCoaching
                cohortId='litt-test-Week34-08-22-2022'
                practiceCaseId='1234567890'
                learnerLogin="login42"
                practiceCaseFeedback=''
            />
        </React.Fragment>
    )
}

export default ComponentPlayground;