import React, { useMemo, useState, useEffect, useContext } from 'react';
import Select, { SelectOption } from "@amzn/meridian/select"
import useCohorts from '../../hooks/useCohorts';
import useCohortContext from '../../hooks/useCohortContext'
import useCohortDetailsById from '../../hooks/useCohortDetailsById';
import { UserDetailsContext } from '../../contexts/UserDetailsProvider'
import Cohort from '../../types/Cohort';
import { useMatch } from 'react-router'
import { CohortDetailsContext } from '../context/CohortDetailsProvider';

interface propTypes {
    changeHandler: (value: string) => void;
}

const CohortSelector = ({changeHandler}: propTypes) => {
    const {userDetails} = useContext(UserDetailsContext)
    const [cohorts, cohortsLoading, cohortsError] = useCohorts(userDetails.login)
    const [sortedCohorts, setSortedCohorts] = useState(cohorts);
    const [selectedCohort, setSelectedCohort] = useState('');
    const [cohortDetailsData] = useCohortDetailsById(selectedCohort);
    const {setCohortDetails} = useContext(CohortDetailsContext)
    const cohortMatch = useMatch({path: 'trainer/cohort/:cohortId', end: false})
    const pcMatch = useMatch({path: 'practice-cases/cohort/:cohortId/caseId/:caseId/' , end: false})


    useEffect(() => {
        if(cohortMatch?.params.cohortId) {
            setSelectedCohort(cohortMatch.params.cohortId)
        }
        if(pcMatch?.params.cohortId) {
            setSelectedCohort(pcMatch.params.cohortId)
        }
      }, [cohortMatch, pcMatch])

    useEffect(() => {
        const cohortDetails = cohortDetailsData[0];
        if (cohortDetails){
            setCohortDetails({cohortId: cohortDetails.cohortId, org: cohortDetails.org, function: ""})
        }
    }, [cohortDetailsData])

    const dropdownLimit = 15;

    const handleChange = (value) => {
        setSelectedCohort(value);
        const selectedCohortObject = cohorts.find((c1) => c1.cohortId === value) as Cohort
        // Change cohort function to be selectedCohortObject.function once
        if (selectedCohortObject) {
            setCohortDetails({cohortId: selectedCohortObject.cohortId, org: selectedCohortObject.org, function: ''});
        }
        changeHandler(value);
    }

    useMemo(() => {
        if(cohorts && !cohortsLoading) {
            setSortedCohorts(cohorts.sort((cohort1, cohort2) => cohort2.startDate - cohort1.startDate).slice(0, dropdownLimit))
        }
    }, [cohorts])



    return (
        <Select 
            placeholder={
                cohortsLoading ? 'Loading your available cohorts...' :
                !cohortsLoading && cohorts.length > 0 ? "Select cohort" : "No cohorts for current user"} 
            value={selectedCohort} 
            onChange={handleChange} 
            size={'medium'}
            width={200}
        >
            {cohorts && sortedCohorts && sortedCohorts.map((cohort) => <SelectOption key={cohort.cohortId} value={cohort.cohortId} label={cohort.cohortId} />)}
        </Select>
    )
}

export default CohortSelector;
