import React, { createContext, SetStateAction, useState } from 'react';

interface CohortDetailsType {
    cohortId: string;
    function: string;
    org: string;
}

interface CohortDetailsContextType {
    cohortDetails: CohortDetailsType;
    setCohortDetails: React.Dispatch<SetStateAction<CohortDetailsType>> 
}

const initDetails: CohortDetailsType = {cohortId: 'bob', org: '', function: ''}


export const CohortDetailsContext = createContext<CohortDetailsContextType>({
    cohortDetails: initDetails, 
    setCohortDetails: () => {}
});

const CohortDetailsProvider = ({children}) => {
    const [cohortDetails, setCohortDetails] = useState<CohortDetailsType>(initDetails);
    const setDetails = (details) => {
        console.log('setting details to', details)
        setCohortDetails(details)
    }
    return (
        <CohortDetailsContext.Provider value={{cohortDetails, setCohortDetails: setDetails}}>
            {children}
        </CohortDetailsContext.Provider>
    );
};


export default CohortDetailsProvider;