import React, { useState, useEffect } from 'react';
import useCohortContext from '../../hooks/useCohortContext';
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from "@amzn/meridian/icon"
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small"
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small"
import CohortInnerTable from './CohortInnerTable';
import { ScoredCohort } from '../../hooks/useScoreCohort'
import Loader from "@amzn/meridian/loader"
import Alert from "@amzn/meridian/alert"
import { useParams } from 'react-router';



const tableColumns = [['totalActions', 'Total Actions'], ['correctActions', 'Correct Actions'], ['arPercentage', 'AR%'], ['avgScore', 'Avg Score']]

const LearnerRow = ({ learnerScores, isExpanded, updateExpandedRows, cohortId }) => {

    const calculateAverageScore = (learnerScores) => {
        let sumOfScores = 0;
        learnerScores.cases.map(learnerCase => {
            sumOfScores = sumOfScores + learnerCase.score;
        })
        return sumOfScores / learnerScores.cases.length;
    };

    const getColumnValue = (learnerScores, tableColumn) => {
        let columnValue = '';
        switch (tableColumn[0]){
            case 'arPercentage':
                columnValue = learnerScores[tableColumn[0]].toFixed(0) + '%';
                break;
            case 'avgScore':
                columnValue = learnerScores.cases.length > 0 ? calculateAverageScore(learnerScores).toFixed(0) + '%' : '-';
                break;
            default:
                columnValue = learnerScores[tableColumn[0]]
        }
        return (
            <TableCell>{columnValue}</TableCell>
        )
    }

    return (
        <>
            <TableRow key={learnerScores.login}>
                <TableCell
                    rowSpan={isExpanded ? learnerScores.length : 1}
                    alignmentVertical="top"
                >
                    <Button
                        type="icon"
                        size="small"
                        onClick={() => updateExpandedRows(learnerScores.login)}
                    >
                        {isExpanded ? (
                            <Icon tokens={chevronDownSmallTokens}>Collapse rows</Icon>
                        ) : (
                            <Icon tokens={chevronRightSmallTokens}>Expand rows</Icon>
                        )}
                    </Button>
                </TableCell>
                <TableCell
                    rowSpan={isExpanded ? learnerScores.length : 1}
                    alignmentVertical="top"
                >
                    <Column spacingInset="200 none none">{learnerScores.login}</Column>
                </TableCell>
                {
                    tableColumns.map((column) => getColumnValue(learnerScores, column))
                }
                {
                    learnerScores.questionPercentages.map((col, ind) => {
                        if (ind !== learnerScores.questionPercentages.length - 1) {
                            return (
                                <TableCell>{col.toFixed(0)}%</TableCell>
                            )
                        }
                    })
                }
            </TableRow>
            {
                isExpanded && (
                    <TableRow key={learnerScores.login + learnerScores.caseId}>
                        <TableCell backgroundColor='secondary'>
                            {/* Empty Cell for Spacing */}
                        </TableCell>
                        <TableCell columnSpan={tableColumns.length + learnerScores.questionPercentages.length + 1}>
                            <div style={{ height: 'fit-content', maxHeight: 300, overflowY: "scroll" }}>
                                <CohortInnerTable cases={learnerScores.cases} cohortId={cohortId} login={learnerScores.login} />
                            </div>
                        </TableCell>
                    </TableRow>
                )
            }
        </>
    )
}

interface CohortTableProps {
    cohortScores: ScoredCohort[],
    cohortScoresLoading: boolean,
    cohortScoresError: null | object,
    columnDisplayNames?: string[]
}
const CohortTable = ({ cohortScores, cohortScoresLoading, cohortScoresError, columnDisplayNames }: CohortTableProps) => {
    const { cohortId } = useParams()
    const { cohortDetails } = useCohortContext()
    const [expandedRows, setExpandedRows] = useState<string[]>([])

    const updateExpandedRows = (row: string) => {
        const newExpandedRows = expandedRows.includes(row)
            ? expandedRows.filter(expandedRow => expandedRow !== row)
            : [...expandedRows, row]

        setExpandedRows(newExpandedRows)
    }

    if (cohortScoresLoading || cohortScoresError) return (<Loader />)
    // if (cohortScoresError) return (<Alert type={'error'}>Could not retrieve scores for cohort: {cohortId}</Alert>)
    if (cohortScores.length === 0) return (<Alert type={'informational'}>No scorable cases for selected cohort{cohortId}</Alert>)

    return (
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <Table  
                    headerRows={1} 
                    spacing="small" 
                    showStripes 
                    showDividers 
                    nestedRows 
                    rowComponents={[TableRow, LearnerRow]}
                    >
                <TableRow key='cohort-table-header'>
                    <TableCell width={60}>
                        {/* Empty table cell to hold the expandable Icon button */}
                    </TableCell>
                    <TableCell>
                        Learner Alias
                    </TableCell>
                    {tableColumns.map((col) =>
                    (
                        <TableCell>
                            {col[1]}
                        </TableCell>
                    )
                    )}
                    {
                        cohortScores[0]['questionPercentages'].map((_, ind) => {
                            if (ind !== cohortScores[0]['questionPercentages'].length - 1) {
                                if (columnDisplayNames) {
                                return (
                                    <TableCell>
                                            {columnDisplayNames[ind]}
                                        </TableCell>
                                    )
                                }
                                return (
                                    <TableCell>
                                        Q{ind + 1}%
                                    </TableCell>
                                )
                            }
                        })
                    }
                </TableRow>
                {
                    cohortScores.map((learnerScoredCohort) => (
                        <LearnerRow
                            key={learnerScoredCohort.login}
                            learnerScores={learnerScoredCohort}
                            isExpanded={expandedRows.includes(learnerScoredCohort.login)}
                            updateExpandedRows={updateExpandedRows}
                            cohortId={cohortId}
                        />
                    ))
                }

            </Table>
        </div>
    )
}


export default CohortTable;