import React from "react";
import ControlGroup from "@amzn/meridian/control-group"
import RadioButton from "@amzn/meridian/radio-button"
import Checkbox from "@amzn/meridian/checkbox"
import { QuestionAnswer } from '../../types/Question'
import Icon from "@amzn/meridian/icon"
import Alert from "@amzn/meridian/alert";
import checkCircleFilledTokens from "@amzn/meridian-tokens/base/icon/check-circle-filled"
import closeCircleTokens from "@amzn/meridian-tokens/base/icon/close-circle"
import './Question.scss'


interface propTypes {
    type: string;
    answers: QuestionAnswer[];
    questionId: string;
    questionLabel: string;
    isReadOnly: boolean,
    requiresAnswer: boolean,
    correctAnswer: string,
    selectedAnswer: undefined | string | string[],
    onChangeHandler: (answer: string | string[], questionId: string) => void;
}

const Question = ({ onChangeHandler, type, answers, questionId, questionLabel, selectedAnswer, isReadOnly, requiresAnswer, correctAnswer }: propTypes) => {

    return (
        <ControlGroup
            label={questionLabel}
            name={questionId}
            onChange={value => onChangeHandler(value, questionId)} // Takes in single value if multiple-choice, array if checkbox
            value={(type !== "multiple-choice" && !selectedAnswer) ? [] : selectedAnswer} //Default undefined, empty array if checkbox input
        >
            {
                requiresAnswer &&
                <Alert type='error' size='small'>Please answer this question</Alert>
            }
            {
                answers.map((answer, index) => { // This was originally going to map to a QuestionAnswer component, but behavior was weird with the input not being a direct child of the ControlGroup component.
                    
                    if (type === "multiple-choice") {
                        return (
                            <RadioButton
                                key={questionId+index}
                                value={answer.answerId}
                                disabled={isReadOnly}
                            >
                                {answer.answerLabel}
                                {
                                    isReadOnly &&
                                    correctAnswer === answer.answerId &&
                                    <Icon tokens={checkCircleFilledTokens} className="green answer-icon"/>
                                }
                                {
                                    isReadOnly &&
                                    correctAnswer &&
                                    selectedAnswer === answer.answerId &&
                                    correctAnswer !== answer.answerId &&
                                    <Icon tokens={closeCircleTokens} className="red answer-icon"/>
                                }
                            </RadioButton>
                        )
                    } else {
                        return <Checkbox
                                key={questionId+index}
                                value={answer.answerId}
                                disabled={isReadOnly}
                            >
                                {answer.answerLabel}
                            </Checkbox>
                    }
                })
            }
        </ControlGroup>
    )
}

export default Question;