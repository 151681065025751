import { useState, useEffect } from 'react';
import apiCaller from '../utils/api';
import QuestionnaireTemplateType from '../types/Template';


const useTemplates = (): [QuestionnaireTemplateType[], boolean, null] => {

    const initTemplates = [] as QuestionnaireTemplateType[];
    const [data, setData] = useState(initTemplates);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {

        //Set loading and error states
        setLoading(true);
        setError(null);

        apiCaller.get('/templates')
            .then((response) => {
                setLoading(false)
                setData(response.data as QuestionnaireTemplateType[])
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [])
    return [data, loading, error];
}

export default useTemplates;