import React from 'react'
import Button from '@amzn/meridian/button'
import Icon from '@amzn/meridian/icon'
import Edit from '@amzn/meridian-tokens/base/icon/edit'
import ToolTip from '@amzn/meridian/tooltip'
import { useNavigate } from 'react-router'

interface propTypes {
    cohortId: string;
    caseId: string;
    mode?: string
}

const EditCaseButton = ({ cohortId, caseId, mode }: propTypes) => {
    const navigate = useNavigate()
    const editCaseNav = () => {
        navigate(`/practice-cases/cohort/${cohortId}/caseId/${caseId}`)
    }

    if (mode === 'nav') {
        return (
            <ToolTip title={'Edit Case'} position={'right'}>
                <Button onClick={editCaseNav} type={'icon'} size={'small'} circular>
                    <Icon tokens={Edit}></Icon>
                </Button>
            </ToolTip>
        )
    }
    return (
        <Button onClick={editCaseNav} circular>
            <Icon tokens={Edit}></Icon>
        </Button>
    )
}

export default EditCaseButton