import React, { useContext } from "react";
import Row from "@amzn/meridian/row"
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import { LEARNER_PERFORMANCE_TABLE_HEADERS } from '../../../utils/constants'
import { UserDetailsContext } from "../../../contexts/UserDetailsProvider";
import usePracticeCasesForLogin from "../../../hooks/usePracticeCasesForLogin";
import PracticeCase from "../../../types/PracticeCase";
import Heading from "@amzn/meridian/heading";
import AggregatedData from './AggregatedData'
import calculateScore from "../../../utils/calculateScore";
import SelectedAnswers from "../../../types/SelectedAnswer";
import QuestionType from "../../../types/Question";
import ExtendedPracticeCase from '../../../types/ExtendedPracticeCase'

interface GroupedCohort {
    [key: string]: {
        cohortId: string,
        cases: PracticeCase
    }
}

export const groupCases = (practiceCases: ExtendedPracticeCase[]) => {
    let casesPerCohort = {}
    for (const practiceCase of practiceCases) {
        if (practiceCase.practiceCaseStatus === 'completed') {
            if (!casesPerCohort[practiceCase.cohortId]) {
                casesPerCohort[practiceCase.cohortId] = {
                    cohortId: practiceCase.cohortId,
                    cases: []
                }
            }

            casesPerCohort[practiceCase.cohortId].cases.push(practiceCase)
        }
    }
    
    return casesPerCohort as GroupedCohort
}

export const getActions = (_case: PracticeCase) => {
    //selectedAnswers is an object where the key is the questionId, and the value is the answerId
    const templateFinalQuestion = _case.template?.templateQuestions[_case.template.templateQuestions.length - 1]
    const finalActionQuestionId = templateFinalQuestion?.questionId
    const trainerAnswerId = _case.primeCase?.selectedAnswers?.[finalActionQuestionId as string]
    const learnerAnswerId = _case.selectedAnswers?.[finalActionQuestionId as string]

    let trainerAction = '-'
    let learnerAction = '-'
    
    if (templateFinalQuestion?.questionAnswers) {
        for (const answer of templateFinalQuestion.questionAnswers) {
            if (answer.answerId === trainerAnswerId) {
                trainerAction = answer.answerLabel
            }
            if (answer.answerId === learnerAnswerId) {
                learnerAction = answer.answerLabel
            }
        }
    }
    
    return { learnerAction, trainerAction}
}

const LearnerPerformanceDashboard = () => {
    const { userDetails } = useContext(UserDetailsContext)
    const { practiceCases, loading, error } = usePracticeCasesForLogin(userDetails.login, true, true)

    if (loading) return <Loader />
    if (error) return <Alert type='error'>An error occurred while fetching cases</Alert>

    const completedCases = !practiceCases ? [] : practiceCases.filter(practiceCase => practiceCase.practiceCaseStatus === 'completed')
    const extendedCases = completedCases.map((practiceCase) => {
        const { trainerAction, learnerAction } = getActions(practiceCase)
        const finalScore = calculateScore(
            practiceCase.template?.templateQuestions as QuestionType[],
            practiceCase.primeCase as PracticeCase,
            practiceCase.selectedAnswers as SelectedAnswers
        )

        return {
            ...practiceCase,
            finalScore,
            trainerAction,
            learnerAction
        } as ExtendedPracticeCase
    })
    const groupedCases = groupCases(extendedCases)
    
    if (Object.keys(groupedCases).length === 0) {
        return <Alert type='informational'>No completed practice cases were found</Alert>
    }

    return (
        <Row
            widths='fill'
        >
            <Column spacingInset={ '400'}>
                <AggregatedData practiceCases={ extendedCases }/>
                <Heading level={1} type="d50">
                    Summary of completed cases
                </Heading>
                <Table showDividers={ true } stickyHeaderRow={ true } headerRows={ 1 }>
                    <TableRow>
                    {
                        LEARNER_PERFORMANCE_TABLE_HEADERS.map((header, index: number) => {
                            return <TableCell key={ index }>{ header }</TableCell>
                        })
                    }
                    </TableRow>
                    {
                        Object.values(groupedCases).map((cohort: any, index: number) => {
                            return (
                                cohort.cases.map((_case: ExtendedPracticeCase, idx: number) => {
                                    return (
                                        <TableRow key={ `row-index-${_case.practiceCaseId}` } highlightOnHover={ true }>
                                            <TableCell>{ _case.practiceCaseId }</TableCell>
                                            <TableCell>
                                                <Text 
                                                    color={ _case.learnerAction !== _case.trainerAction ? 'error' : 'primary' }
                                                >
                                                    { _case.learnerAction }
                                                </Text>
                                            </TableCell>
                                            <TableCell>{ _case.trainerAction }</TableCell>
                                            <TableCell>
                                            {
                                                _case?.primeCase?.practiceCaseStatus !== 'completed'
                                                ?   'Waiting for Trainer'
                                                :   'Completed'
                                            }
                                            </TableCell>
                                            <TableCell>
                                            {
                                                _case.practiceCaseFeedback
                                                ?   _case.practiceCaseFeedback
                                                :   '-'
                                            }
                                            </TableCell>
                                            <TableCell>
                                            {
                                                _case.finalScore
                                            }
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    href={ `/#/practice-cases/cohort/${_case.cohortId}/caseId/${_case.practiceCaseId}` }
                                                    rel="noopener noreferrer"
                                                    target='_blank'
                                                >
                                                    View
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            )
                        })
                    }
                </Table>
            </Column>
        </Row>
    )
}

export default LearnerPerformanceDashboard;