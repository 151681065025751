import React, { useEffect, useState } from 'react';
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import Heading from "@amzn/meridian/heading"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import CohortSelector from './CohortSelector'
import NewPracticeCase from '../NewPracticeCase/NewPracticeCase';
import { useNavigate } from 'react-router';
import usePracticeCase from '../../hooks/usePracticeCase';
import Select, { SelectOption } from '@amzn/meridian/select';
import { load } from 'cheerio';
import PracticeCase from '../../types/PracticeCase';
import Header from '../Header/Header';



const TrainerLanding = () => {

    return (
        <Box spacingInset={'500'}>
            <Heading level={1} alignment={'center'}>
                Trainer Dashboard
            </Heading>
        </Box>
    )
}

export default TrainerLanding;