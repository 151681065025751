import React, { useContext }  from "react"
import Card, { CardHeader } from "@amzn/meridian/card"
import Heading from "@amzn/meridian/heading"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import { useNavigate } from "react-router";
import useSecureLink from '../../../hooks/useSecureLink'
import PracticeCase from '../../../types/PracticeCase'
import textFormatter from '../../../utils/textFormatter'
import { UserDetailsContext } from "../../../contexts/UserDetailsProvider"
import './PracticeCaseCard.scss'

interface propTypes {
    practiceCase: PracticeCase
}

const PracticeCaseCard = (props: propTypes) => {
    const { practiceCase } = props
    const navigate = useNavigate()
    const { userDetails } = useContext(UserDetailsContext)

    const {login} = userDetails
    const {cohortId, practiceCaseId, caseUrl, org} = practiceCase

    const practiceCaseUrl = `/practice-cases/cohort/${cohortId}/caseId/${practiceCaseId}`
    const openPracticeCase = () => navigate(practiceCaseUrl)


    const [secureLinkUrl, secureLinkLoading, secureLinkError] = useSecureLink(login, caseUrl, org)
    const openPracticeCaseSecureLink = () => window.open(secureLinkUrl)
    return (
        <Card width={300} spacingInset="none">
            <CardHeader>
                <Heading level={2} type="h400" className="practice-card-header">
                    Practice Case
                </Heading>
            </CardHeader>
            <Column spacingInset="400 500" spacing="200">
                {secureLinkUrl &&
                    <Button type="tertiary" onClick={ openPracticeCaseSecureLink }>View Case</Button>
                }
                <Text>Case Id: 
                    <Text tag="string" type="h100">
                        { ' ' + practiceCase.practiceCaseId }
                    </Text>
                </Text>

                <Text alignment="justify">
                    Case Status: 
                    <Text tag="string" type="h100">
                        { ' ' + textFormatter(practiceCase.practiceCaseStatus) }
                    </Text>
                </Text>
                <Text type="b200">After viewing case in Paragon/Nautilus, please click Start below to answer case questions</Text>
            </Column>
            <Column spacingInset="400 500">
                <Row widths={["fill", "fit"]}>
                    <Button type="primary" onClick={ openPracticeCase }>Start Practice</Button>
                </Row>
            </Column>
        </Card>
    )
}

export default PracticeCaseCard;