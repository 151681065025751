import React, { useState, useEffect } from 'react';
import Button from "@amzn/meridian/button"
import Column from '@amzn/meridian/column';
import Loader from "@amzn/meridian/loader"
import Text from "@amzn/meridian/text"
import Alert from "@amzn/meridian/alert"
import Question from "./Question"
import SelectedAnswers from '../../types/SelectedAnswer';


interface propTypes {
    template,
    selectedAnswers: SelectedAnswers,
    correctAnswers: SelectedAnswers,
    missingFields: {},
    isReadOnly: boolean,
    handleChange: (answer: string | string[], questionId: string) => void
}

//TODO
//Update props to propTypes
const QuestionnaireTemplate = (props: propTypes) => {
    const {
        template,
        selectedAnswers,
        handleChange,
        missingFields,
        isReadOnly,
        correctAnswers
    } = props

    if (!template) return <Alert type='error'>No template provided</Alert>

    return (
        <React.Fragment>
            <Column spacing={"500"}>
            {
                template?.templateQuestions.map((q) => {
                    let correctAnswer = correctAnswers?.[q.questionId]
                    
                    return <Question
                        type={q.questionType}
                        answers={q.questionAnswers}
                        questionId={q.questionId}
                        questionLabel={q.questionLabel}
                        onChangeHandler={handleChange}
                        selectedAnswer={selectedAnswers[q.questionId]}
                        key={q.questionId}
                        isReadOnly={ isReadOnly }
                        requiresAnswer={ missingFields[q.questionId] }
                        correctAnswer={correctAnswer as string}
                    />
                })
            }
            </Column>
        </React.Fragment>
            
    )
}

export default QuestionnaireTemplate;