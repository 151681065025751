import { useState, useEffect } from 'react';
import PracticeCase from '../types/PracticeCase';
import apiCaller from '../utils/api';



const usePrimeCases = (cohortId): [PracticeCase[], boolean, null, VoidFunction] => {

    const initCohorts = [] as PracticeCase[];
    const [data, setData] = useState(initCohorts);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refetcher, setRefetcher] = useState({})

    const refetch = () => setRefetcher({})

    useEffect(() => {

        //Set loading and error states
        setLoading(true);
        setError(null);

        apiCaller.get(`/trainer/cohort/cases?cohortId=${cohortId}`)
            .then((response) => {
                setLoading(false)
                setData(response.data as PracticeCase[])
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [cohortId, refetcher])
    return [data, loading, error, refetch];
}

export default usePrimeCases;