import React, { useState, useCallback } from 'react';
import Table, { TableRow, TableCell } from "@amzn/meridian/table"
import { TableSortDirection } from '@amzn/meridian/table/table';
import Link from "@amzn/meridian/link"
import { useNavigate } from 'react-router';
import LearnerCoaching from './LearnerCoaching';


const CohortInnerTable = ({ cases, cohortId, login }) => {
    //TODO Change this to an array of objects & move it to constants file
    const innerTableColumns = ['caseId', 'trainerAction', 'learnerAction', 'score', 'actions'];
    const navigate = useNavigate()

    const [sortColumn, setSortColumn] = useState("score")
    const [sortDirection, setSortDirection] = useState("descending")

    const onSort = useCallback(({ sortColumn, sortDirection }) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }, [])

    cases.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "ascending" ? -1 : 1
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "ascending" ? 1 : -1
        }
        return 0
      })
    

    return (
        <Table 
            headerRows={1} 
            stickyHeaderRow
            sortColumn={sortColumn}
            sortDirection={sortDirection as TableSortDirection}
            onSort={onSort}
        >
            <TableRow>
                {
                    innerTableColumns.map((col) => (
                        <TableCell sortColumn={col}>
                            {col.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + col.replace(/([A-Z])/g, " $1").slice(1)}
                        </TableCell>
                    ))
                }
            </TableRow>
            {
                cases.map((caseData) => {
                    return (
                        <TableRow>
                            {
                                innerTableColumns.map((col) => {
                                    if (col === 'actions') {
                                        return <TableCell>
                                            <LearnerCoaching
                                                cohortId={ cohortId }
                                                practiceCaseId={ caseData.caseId }
                                                learnerLogin={ login }
                                                practiceCaseFeedback={ caseData.practiceCaseFeedback ? caseData.practiceCaseFeedback : '' }
                                            /> 
                                        </TableCell>
                                    } else if (col === 'caseId') {
                                        return <TableCell>
                                            <Link
                                                onClick={() => navigate(`/practice-cases/cohort/${cohortId}/caseId/${caseData.caseId}/login/${login}`)}
                                            >
                                                {caseData[col]}
                                            </Link>
                                        </TableCell>
                                    } else {
                                        return <TableCell>
                                            {(col === 'score' ? caseData[col].toFixed(2) : caseData[col]) + (col === "score" ? '%' : '')}
                                        </TableCell>
                                    }
                                })
                            }
                        </TableRow>
                    )
                })
            }
        </Table>
    )
}

export default CohortInnerTable