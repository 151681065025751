import React, { useEffect, useState } from "react"
import SideMenu, { SideMenuLink, SideMenuTitle } from "@amzn/meridian/side-menu"
import CohortSelector from "./CohortSelector"
import usePrimeCases from "../../hooks/usePrimeCases"
import { NavigateFunction, useNavigate, useMatch, useLocation } from "react-router"
import Loader from "@amzn/meridian/loader"
import Text from '@amzn/meridian/text'
import Column from "@amzn/meridian/column"
import EditCaseButton from './EditCaseButton'
import Row from '@amzn/meridian/row'

interface propTypes {
  login: string
}

interface subPropTypes {
  selectedCohort: string;
  caseId: string
  setSelectedLink: (linkName: string) => void
  selectedLink: string
  navigate: NavigateFunction
  login: string
}
const TrainerSideLink = ({ selectedCohort, caseId, login, setSelectedLink, selectedLink, navigate}: subPropTypes) => {
  return (
    <SideMenuLink
      href={`#${caseId}`}
      onClick= {
        (hash) => {
          setSelectedLink(hash)
          // navigate(`/practice-cases/cohort/${selectedCohort}/caseId/${caseId}/login/${login}`)
          navigate(`/trainer/cohort/${selectedCohort}/case/${caseId}/`)
        }
      }
      selected={selectedLink === '#'+caseId}
    >
      {/* <Row type={'outline'} width={200} alignmentHorizontal={'center'} widths={["grid-<8>", "grid-<4>"]}>
        <Text>{caseId}</Text> 
        <EditCaseButton cohortId={selectedCohort} caseId={caseId} mode={'nav'} />
      </Row> */}
      {caseId}
    </SideMenuLink>
  )
}
const TrainerSideMenu = () => {
  const cohortMatch = useMatch({path: 'trainer/cohort/:cohortId', end: false})
  const pcMatch = useMatch({path: 'practice-cases/cohort/:cohortId/caseId/:caseId/' , end: false})
  const learnerMatch = useMatch({path: 'learner/' , end: false})
  const location = useLocation()

  const [selectedCohort, setSelectedCohort] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [selectedLink, setSelectedLink] = useState('')
  const [practiceCases, loading, error, refetchPracticeCases]  = usePrimeCases(selectedCohort)
  const navigate = useNavigate()


  useEffect(() => {
    if(cohortMatch) {
      changeSelectedCohort(cohortMatch.params.cohortId, false)
    }
    if(pcMatch) {
      changeSelectedCohort(pcMatch.params.cohortId, false)
      setSelectedLink('#'+pcMatch.params.caseId)
    }
  }, [cohortMatch, pcMatch])

  useEffect(() => {
    if(pcMatch) {
      refetchPracticeCases()
    }
  }, [location])

  useEffect(() => {
    if (practiceCases && !loading) {
      setExpanded(true)
    }
  }, [selectedLink, practiceCases, loading])

  const changeSelectedCohort = (cohort, nav=true) => {
    setSelectedCohort(cohort)
    setSelectedLink('#'+cohort)
    if(nav) {
      navigate(`/trainer/cohort/${cohort}`)
    }
  }

  if(learnerMatch) return (<></>)

  return (
    <SideMenu width={300} linkComponents={[SideMenuLink, TrainerSideLink]}>
      <SideMenuTitle >
        <Column spacingInset={'200'}>
          <Text type={'h500'}>Select a Cohort</Text>
          <CohortSelector changeHandler={changeSelectedCohort} />
        </Column>
      </SideMenuTitle>
      {
        selectedCohort &&
        <SideMenuLink
        selected={selectedLink === '#'+selectedCohort}
        open={expanded}
        href={`#${selectedCohort}`}
        onClick={hash => {
          setSelectedLink(hash)
          // Toggle the link's open state when clicked multiple times
          setExpanded(!expanded)
          navigate(`/trainer/cohort/${selectedCohort}`)
        }}
      >
        {selectedCohort}
        {
          loading &&
          <Loader />
        }
        {
          !loading && !practiceCases &&
          <SideMenuLink disabled>
              There are no practice cases for this cohort. 
          </SideMenuLink>
        }
        {practiceCases && Array.isArray(practiceCases) &&
          practiceCases.map((pc) => {
            return (
              <TrainerSideLink 
                  selectedCohort={selectedCohort} 
                  caseId={pc.practiceCaseId} 
                  setSelectedLink={setSelectedLink} 
                  selectedLink={selectedLink} 
                  navigate={navigate}
                  login={pc.login}
              />
            )
          })
        }
      </SideMenuLink> 
      } 
    </SideMenu >
  )
}

export default TrainerSideMenu
