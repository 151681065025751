import React, { useState, useRef } from "react";
import Icon from "@amzn/meridian/icon"
import Button from "@amzn/meridian/button"
import Popover, { PopoverHeader } from "@amzn/meridian/popover"
import Textarea from "@amzn/meridian/textarea"
import supportChatTokens from "@amzn/meridian-tokens/base/icon/support-chat"
import Loader from "@amzn/meridian/loader"
import Alert from "@amzn/meridian/alert"
import Row from "@amzn/meridian/row";
import { updatePracticeCaseFeedback } from '../../utils/api'

type propTypes = {
    cohortId: string,
    practiceCaseId: string,
    learnerLogin: string,
    practiceCaseFeedback: string
}

const LearnerCoaching = (props: propTypes) => {
    const { cohortId, practiceCaseId, learnerLogin, practiceCaseFeedback} = props
    const buttonRef = useRef()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [feedback, setFeedback] = useState(practiceCaseFeedback)

    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)
    const submitCoaching = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        //TODO check why loader is not shown (maybe it is too fast?)
        setLoading(true)
        const feedbackParams = {
            cohortId,
            practiceCaseId,
            login: learnerLogin,
            practiceCaseFeedback: feedback,
        }

        try {
            console.log('submitting feedback', feedbackParams)
            updatePracticeCaseFeedback(feedbackParams)
        } catch (error) {
            console.log('An error occurred while trying to save practice case feedback')
            setShowErrorMessage(true)
        }
        onClose()
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Button type='icon' onClick={ onOpen } circular ref={ buttonRef }>
                <Icon tokens={ supportChatTokens }></Icon>
            </Button>
            <Popover
                anchorNode={ buttonRef.current }
                open={ open }
                onClose={ onClose }
                position="left"
            >
                <PopoverHeader closeLabel="Close">Feedback for {learnerLogin} Case {practiceCaseId}</PopoverHeader>
                <form onSubmit={ submitCoaching }>
                    <Textarea
                        value={ feedback }
                        onChange={ setFeedback }
                        placeholder={ `Enter your feedback for ${ learnerLogin }` }
                        resize='none'
                    />
                    {
                        showErrorMessage &&
                        <Alert type='error' size="small">
                            An error occurred while trying to save your feedback
                        </Alert>
                    }
                    <Row alignmentHorizontal="end">
                        <Button type='primary' disabled={ feedback === '' } submit={ true }>
                            {
                                loading &&
                                <Loader size='small' />
                            }
                            Save
                        </Button>
                    </Row>
                </form>
            </Popover>
        </React.Fragment>
    )
}

export default LearnerCoaching;