import axios from 'axios';
import { getMidwayJwtToken } from '../auth/MidwayJwtToken'
import { API_BASE_PATH } from './constants'

const apiCaller = axios.create({
    baseURL: API_BASE_PATH
})

apiCaller.interceptors.request.use(async (config) => {
    config.headers["Authorization"] = `Bearer ${await getMidwayJwtToken()}`;
    config.headers["Content-Type"] = "application/json"
    return config;
})

const createPracticeCasesForCohort = async (params: any) => {
    const promise = apiCaller.post('/practice-cases', params)
    return promise
}

const updatePracticeCase = async (params: any) => {
    return apiCaller.patch(`/practice-cases`, params)
}

const updatePracticeCaseFeedback = async (params: any) => {
    return apiCaller.patch(`/practice-cases/feedback`, params)
}

export {
    createPracticeCasesForCohort,
    updatePracticeCase,
    updatePracticeCaseFeedback
}

export default apiCaller
