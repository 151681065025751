import jwtDecode from "jwt-decode";
import { getMidwayJwtToken } from '../auth/MidwayJwtToken';
import DecodedMidwayToken from "../types/DecodedMidwayToken";

const getAlias = async () => {
    return await getMidwayJwtToken()
    .then(resp => {
        const decodedToken = jwtDecode(resp) as DecodedMidwayToken
        const alias = decodedToken.sub
        return alias
    })
}

export default getAlias