// Promise polyfill
import 'core-js/features/promise';
import jwtDecode from "jwt-decode";
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import { initializeAppSettings } from './config/AppSettings';
import DecodedMidwayToken from "./types/DecodedMidwayToken";
import UserDetailsProvider from './contexts/UserDetailsProvider'
import CohortDetailsProvider from './components/context/CohortDetailsProvider';

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  const token = await getMidwayJwtToken();
  const decodedToken = jwtDecode(token) as DecodedMidwayToken
  const alias = decodedToken.sub

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  render(
    <HashRouter>
      <UserDetailsProvider>
        <CohortDetailsProvider>
          <App login={ alias }/>
        </CohortDetailsProvider>
      </UserDetailsProvider>
    </HashRouter>,
    document.querySelector('#app')
  );
})();
