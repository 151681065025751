import PracticeCase from '../types/PracticeCase'
import QuestionType from '../types/Question'
import SelectedAnswers from '../types/SelectedAnswer'

const calculateScore = (templateQuestions: QuestionType[], primeCase: PracticeCase, learnerAnswers: SelectedAnswers) => {
    const correctAnswers = primeCase.selectedAnswers
    if (!correctAnswers || primeCase.practiceCaseStatus !== 'completed') {
        return '-'
    }

    let questionWeights = {}
    let totalWeight = 0
    let obtainedWeight = 0
    
    for (const question of templateQuestions) {
        totalWeight += question.questionWeight as number
        questionWeights[question.questionId] = question.questionWeight
    }

    Object.keys(correctAnswers).forEach(questionId => {
        if (correctAnswers[questionId] === learnerAnswers[questionId]) {
            obtainedWeight += questionWeights[questionId]
        }
    })
    
    const score = (obtainedWeight / totalWeight) * 100
    return score.toFixed(2)
}

export default calculateScore