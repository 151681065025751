import React, { FormEvent, useState, useContext, useEffect, useReducer } from 'react'
import { Params, useNavigate } from "react-router";
import { HashRouterProps, useParams } from 'react-router-dom'
import Row from "@amzn/meridian/row"
import Link from "@amzn/meridian/link"
import Text from "@amzn/meridian/text"
import Heading from '@amzn/meridian/heading';
import Button from "@amzn/meridian/button"
import Textarea from "@amzn/meridian/textarea"
import Loader from '@amzn/meridian/loader'
import Alert from '@amzn/meridian/alert'
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Tile from "@amzn/meridian/tile"
import Metric from "@amzn/meridian/metric"
import PracticeCase from '../../types/PracticeCase'
import usePracticeCase from '../../hooks/usePracticeCase'
import QuestionnaireTemplate from '../QuestionairreTemplate/QuestionnaireTemplate';
import { updatePracticeCase } from '../../utils/api'
import { UserDetailsContext } from "../../contexts/UserDetailsProvider"
import SelectedAnswers from '../../types/SelectedAnswer';
import calculateScore from '../../utils/calculateScore'
import QuestionType from '../../types/Question';


const PracticeCase = () => {
    const { userDetails } = useContext(UserDetailsContext)
    const { cohortId, practiceCaseId, login }: Readonly<Params<string>> = useParams()
    const { practiceCase, loading, error } = usePracticeCase(cohortId as string, practiceCaseId as string, login ? login : userDetails.login)
    const [comments, setComments] = useState('')
    //@ts-ignore 
    const [selectedAnswers, setSelectedAnswers] = useState({})
    //TODO
    //Add saving loader on the upper right
    const [showAutoSave, setShowAutoSave] = useState(false)
    const [missingFields, setMissingFields] = useState<{}>({})
    const navigate = useNavigate()
    const onExit = () => navigate('/')

    useEffect(() => {
        // Load selected answers if applicable
        if(practiceCase) {
            setSelectedAnswers(practiceCase.selectedAnswers ? practiceCase.selectedAnswers : [])
            if (userDetails.role === 'trainer') {
                setComments(practiceCase.practiceCaseComments ? practiceCase.practiceCaseComments : '')
            } else if (userDetails.role === 'learner') {
                setComments(practiceCase.primeCase?.practiceCaseComments ? practiceCase.primeCase.practiceCaseComments : '')
            }
        }
    }, [practiceCase])

    const generateUpdateParams = (status: string, answers: {}) => {
        return {
            cohortId,
            practiceCaseId,
            login: userDetails.login,
            selectedAnswers: answers,
            practiceCaseComments: comments,
            practiceCaseStatus: status
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        let _missingFields = {}
        practiceCase?.template?.templateQuestions.forEach(question => {
            if (!selectedAnswers[question.questionId])
                _missingFields[question.questionId] = true
        })

        if (Object.keys(_missingFields).length > 0) {
            setMissingFields(_missingFields)
        } else {
            setMissingFields({})
            const params = generateUpdateParams('completed', selectedAnswers as SelectedAnswers)
            updatePracticeCase(params)
            .then(res => {
                if (userDetails.role === 'trainer') {
                    navigate(`/trainer/cohort/${ cohortId }`)
                } else {
                    navigate('/')
                }
            })
        }
    }
    
    const handleChange = (value: string | string[], questionId: string) => {
        const updatedAnswers = {
            ...selectedAnswers,
            [questionId]: value
        }
        setSelectedAnswers(updatedAnswers)
        const params = generateUpdateParams('inProgress', updatedAnswers)
        updatePracticeCase(params)
        .then(res => console.log('partial res', res))
    }

    if (error) return <Alert type='error'>An error ocurred while fetching the practice case details</Alert>
    if (loading) return <Loader type='circular' />

    let isReadOnly = false
    if (userDetails.role === 'trainer') {
        isReadOnly = practiceCase?.login !== userDetails.login
    } else if (userDetails.role === 'learner') {
        isReadOnly = practiceCase?.practiceCaseStatus === 'completed'
    }
    
    return (
            <form onSubmit={ handleSubmit }>
        <Column spacingInset='400 none' spacing='500'>
                <Row>
                    <Text type='d50'>
                        Case/Task ID { `${ practiceCaseId }` }
                    </Text>
                </Row>
                {
                    isReadOnly &&
                    practiceCase?.primeCase &&
                    userDetails.role !== 'trainer' &&
                    <Row >
                        <Box>
                            <Tile spacingInset="450">
                                <Heading level={2} type="h200" color="secondary">
                                    Total Score
                                </Heading>
                                {
                                    !practiceCase.primeCase.selectedAnswers
                                    ?   <Text>Pending Trainer Completion</Text>
                                    :   <Metric numberFormat={{ maximumSignificantDigits: 5, notation: "compact" }} >
                                        {
                                            calculateScore(
                                                practiceCase?.template?.templateQuestions as QuestionType[],
                                                practiceCase?.primeCase,
                                                practiceCase?.selectedAnswers as SelectedAnswers
                                            )
                                        }
                                        </Metric>
                                }
                            </Tile>
                        </Box>
                    </Row>
                }
                <Row >
                    <QuestionnaireTemplate
                        template={ practiceCase?.template }
                        handleChange={ handleChange }
                        selectedAnswers={ selectedAnswers }
                        isReadOnly={ isReadOnly }
                        missingFields={ missingFields }
                        correctAnswers={ practiceCase?.primeCase?.selectedAnswers as SelectedAnswers }
                    />
                </Row>
                {
                    (userDetails.role === 'trainer' ||
                    (userDetails.role === 'learner' && isReadOnly)) &&
                    <Row
                        alignmentVertical='top'
                    >
                        <Column spacing='400'>
                            <Text tag='label' type='h100'>
                                Case Comments
                            </Text>
                            {
                                userDetails.role === 'learner'
                                ?   <Box type="fill"  spacingInset="400">
                                        <Text>{ comments ? comments : 'No comments have been entered for this case.' }</Text>
                                    </Box>
                                :   <Textarea
                                        name='comments'
                                        value={ comments }
                                        onChange={ setComments }
                                        placeholder="Enter your comments"
                                        error={ false }
                                        disabled={ isReadOnly }
                                        constraintText='These are general comments that will be shown to every cohort member once they complete the practice case.'
                                    />

                            }
                        </Column>
                    </Row>
                }
                {
                    !isReadOnly &&
                    <Row
                        alignmentVertical='top'
                    >
                        <Row>
                            <Button type='primary' submit={ true }>Save</Button>
                            <Button type='secondary' onClick={ onExit }>Leave</Button>
                            {
                                Object.keys(missingFields).length > 0 &&
                                <Alert type='error'>Not all questions have been answered, please go back and answer all questions</Alert>
                            }
                        </Row>
                    </Row>
                }
        </Column>
            </form>
    )
}

export default PracticeCase